import { navigate, type PageProps } from 'gatsby'
import React from 'react'
import Head from 'components/Head'
import Layout from 'components/Layout'
import Home from 'components/landing/Home'
import paths from 'constants/paths'
import storage from 'constants/storage'
import * as cookie from 'libs/cookie'

type Props = PageProps

export default function HomeBalanced({ location }: Props) {
  React.useEffect(() => {
    cookie.createCookie(storage.HOME_KEY, storage.HOME_BALANCED)
  }, [])

  React.useEffect(() => {
    // globalContext.isAuthed isn't ready on mount
    // and adding to dep causes redirect away soon as they auth
    if (cookie.getCookie(storage.REFRESH_TOKEN_KEY)) {
      navigate(paths.HOME_AUTHED, { replace: true })
    } else if (paths.getHomePath() !== location.pathname) {
      navigate(paths.getHomePath(), { replace: true })
    }
  }, [location.pathname])

  return (
    <Layout className="Home" data-test-id="home">
      <Head title="Age Bold – Variety of online fitness classes for older adults" titleOnly />
      <Home />
    </Layout>
  )
}
